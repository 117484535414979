import { Component } from "react";
import "./FeatureContainer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import React from "react";



export default class FeatureContainer extends Component {
  render() {
    return (
      <div className="feature-container">
        <div className="feature-container-text">
          <h1>{this.props.blockTitle}</h1>
          {this.props.points.map((point, index) => {
            return (
              <div key={index} className="feature-container-points">
                <FontAwesomeIcon className="tick-green" icon={faCheck} />
                <span className="feature-point-text">{point}</span>
              </div>
            );
          })}
          <a href="https://calendly.com/dealigense/demo"
              target = "_blank"
              rel="noreferrer"
              >
          <button id="btn-free-consultation" className="btn-primary">
            Request a Demo
          </button></a> 
        </div>
        <div className="feature-container-graphic">
          <img
            className="feature-container-graphic-img"
            src={this.props.image}
            alt={this.props.blockTitle}
          ></img>
        </div>
      </div>
    );
  }
}
