import { Route, Routes } from "react-router-dom";
import "./App.scss";
import Header from "./components/header/Header";
import Pricing from "./components/pricing/Pricing";
import Home from "./components/home/Home";

function App() {
  return (
    <div>
      <Header></Header>
      <main>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/pricing" element={<Pricing />}></Route>
        </Routes>
      </main>
    </div>
  );
}

export default App;
