import "./Home.scss";
import { Component } from "react";
import {
  faFlask,
  faPencil,
  faDiagramProject,
  faImage,
} from "@fortawesome/free-solid-svg-icons";
import FeaturePill from "../shared/feature-pill/FeaturePill";
import FeatureContainer from "../shared/feature-container/FeatureContainer";





export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlightCaption: 0,
      selectedFeature: 0
    };

    setInterval(() => {
      let index = this.state.highlightCaption;
      if (index !== this.featureCaptions.length - 1)
        this.setState({
          highlightCaption: index + 1
        });
      else
        this.setState({
          highlightCaption: 0
        });
    }, 2500);

    setInterval(() => {
      let index = this.state.selectedFeature;
      if (index !== this.featureDetailTitles.length - 1)
        this.setState({
          selectedFeature: index + 1
        });
      else
        this.setState({
          selectedFeature: 0
        });
    }, 10000);
  }

  featureCaptions = ["Personalized", "Data Driven", "Social Media"];

  trustedCompanies = [
    {
      name: "Atlantic Bakery",
      image: "/images/logo.png"
    },
    {
      name: "Burger Boy",
      image: "/images/logo1.png"
    },
    {
      name: "wsi",
      image: "/images/logo2.png"
    },
    {
      name: "Jamaican Spiked Patties",
      image: "/images/logo3.png"
    },
    {
      name: "Sushi House",
      image: "/images/logo4.png"
    },
    {
      name: "Sparetime Store",
      image: "/images/logo5.png"
    }
  ];

  featureDetailTitles = [
    {
      icon: faFlask,
      title: "Personal marketing specialist",
      blockTitle: "Your busy with your customers, leave the marketing to us!",
      points: [
        "Get a dedicated marketing specialist focused on driving customers to your business",
        "On-demand support on your social media marketing needs",
        "Expert advice on social media strategy and marketing campaigns backed by analytics",
      ],
      image: "/images/consultant.svg"
    },
    {
      icon: faFlask,
      title: "Customizeable promotions",
      blockTitle: "Create memorable promotions tailored to each customer",
      points: [
        "Create unique promotions that fit your customer's needs",
        "Apply time constraints to any promotions",
        "Track popularity and progress of promotions",
      ],
      image: "/images/feature_promotions.svg"
    },
    {
      icon: faPencil,
      title: "Loyalty reward system",
      blockTitle: "Boost loyalty and customer retention with our easy-to-use point system.",
      points: [
        "Reward customers for purchases with points redeamable for merchandize and services.",
        "Use point rewards for any type of promotions",
        "Share points among multiple merchants",
      ],
      image: "/images/feature_points.svg"
     
    },
    {
      icon: faDiagramProject,
      title: "Effortless social media marketing",
      blockTitle: "You need a constant social media presence but don't have time.  Don't worry, we make it easy!",
      points: [
        "Automatically create social media posts for public holidays",
        "Advertise your promotions on all social media platforms",
        "Analyse and improve promotions based on interactions",
        "Automatically respond to comments and reviews from customers"
      ],
      image: "/images/feature-social_media_marketing2.svg"
    },
    {
      icon: faImage,
      title: "Timely perks app",
      blockTitle: "Track promotions and sales through the Timely Perks app",
      points: [
        "Have your loyal customers be notified of latest promotions through our app",
        "Encourage customers to make social media posts and win promotions (beta)",
      ],
      image: "/images/feature_mobile_app.svg"
    },
   /* {
      icon: faMicrochip,
      title: "AI generated social media posts",
      blockTitle: "Up to 15X Faster Content Production with AI",
      points: [
        "Write virtually anything using our ChatGPT powered AI assistant",
        "Regenerate and refine content as needed",
        "Preview AI created content instantly"
      ],
      image: "/images/feature-ai-generation.webp"
    },
    {
      icon: faLanguage,
      title: "Generate sales on social media",
      blockTitle: "Zero effort customized AI postings that mimic",
      points: [
        "Over 100+ locales supported",
        "Use API or manual translation services",
        "Manage SEO metadata in multiple languages"
      ],
      image: "/images/feature-localization.webp"
    }*/
  ];

  selectFeature = (text) => {
    this.setState(
      {
        selectedFeature: this.featureDetailTitles.findIndex(
          (feature) => feature.title === text
        )
      },
      () => {
        console.log(this.state.selectedFeature);
      }
    );
  };

  render() {
    return (
      <>
        <div id="home-container">
          <div id="home-features">
            {this.featureCaptions.map((caption, index) => {
              return (
                <h1
                  key={caption}
                  className={
                    this.state.highlightCaption === index
                      ? "home-feature-item home-feature-item-bold"
                      : "home-feature-item"
                  }
                >
                  {caption}
                </h1>
              );
            })}
            <h1 className="home-feature-item home-feature-item-bold">
              Marketing Platform
            </h1>
            <div id="home-features-action-buttons">


              <a href="https://calendly.com/dealigense/demo"
              target = "_blank"
              rel="noreferrer"
              >
              <button className="home-features-btn btn-primary">
                Request a Demo
              </button>
              </a>


              <a href="https://www.youtube.com/watch?v=3MwMII8n1qM"
              target = "_blank"
              rel="noreferrer"

              >
              <button className="home-features-btn btn-secondary"
              >
                Watch Demo Video
              </button>
              </a>
            </div>
            <div id="home-features-text">
              TRUSTED BY BUSINESSES EVERYWHERE
            </div>
            <div id="home-feature-company-images">
              {this.trustedCompanies.map((company) => {
                return (
                  <img
                    className="home-feature-company-image"
                    key={company.name}
                    src={company.image}
                    alt={company.name}
                  ></img>
                );
              })}
            </div>
          </div>
          <div id="home-graphic">
            <img
              id="home-graphic-image"
              src="/images/homepage-graphics.webp"
              alt="Home Page Graphic"
            ></img>
          </div>
        </div>
        <div id="home-feature-details">
          <h1>
          The all-in-one personalized marketing platform for your business.
          </h1>
          <div id="home-feature-details-pills">
            {this.featureDetailTitles.map((feature, index) => {
              return (
                <FeaturePill
                  key={index}
                  isSelected={this.state.selectedFeature === index}
                  title={feature.title}
                  selectFeature={this.selectFeature}
                  icon={feature.icon}
                ></FeaturePill>
              );
            })}
          </div>
          <div id="home-feature-details-container">
            <FeatureContainer
              blockTitle={
                this.featureDetailTitles[this.state.selectedFeature].blockTitle
              }
              points={
                this.featureDetailTitles[this.state.selectedFeature].points
              }
              image={this.featureDetailTitles[this.state.selectedFeature].image}
            ></FeatureContainer>
          </div>
        </div>
      </>
    );
  }
}
