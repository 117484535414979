import { Component } from "react";
import "./FeaturePill.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class FeaturePill extends Component {
  render() {
    return (
      <div
        className={
          this.props.isSelected ? "feature-pill-selected" : "feature-pill"
        }
        onClick={() => {
          this.props.selectFeature(this.props.title);
        }}
      >
        <FontAwesomeIcon className="feature-pill-icon" icon={this.props.icon} />
        <span className="feature-pill-text">{this.props.title}</span>
      </div>
    );
  }
}
